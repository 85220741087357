import React from 'react';
import {Container} from "reactstrap"

const Index = () => {
	return (

		<Container  className="text-center my-5" >
		<h3 style={{color : "rgb(27, 34, 140)",  marginBottom : '12px', fontWeight : "bold"}} className="text-uppercase">
		Hire from world vetted software engineers through 4U institute</h3>
		
		<p style={{color : "rgb(103, 118, 139)"}}>
We are building a pipeline of the most creative and innovative minds across the globe. Save money, time and resources in hiring the best junior position through our system.
</p>
		
		</Container>
		
	)
}

export default Index