import React from 'react';
import Hero from "./Hero"
import ApplyForm from "./ApplyForm"



const Index = () => {
	return (
		<>
		<Hero />
		<ApplyForm />
		</>
	)
}

export default Index