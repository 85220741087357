import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem, Button, UncontrolledDropdown, 
  DropdownItem, DropdownMenu, DropdownToggle
  
} from 'reactstrap';
import {useNavigate} from "react-router-dom"
import logo from "./logo.jpg"

function Index(args) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const navigate = useNavigate()

  return (
    <>
      <Navbar collapseOnSelect expand="md" className="shadow">
      

      <NavbarBrand>
    <img src={logo} className="mb-2" width="100px" alt="logo" 
    style={{cursor : 'pointer'}} 
    onClick={() => navigate("/")}/></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav  navbar>
            <NavItem className="mx-3 mt-2 text-uppercase" style={{fontSize : "12px", color : "rgb(29, 33, 141)",  
            fontWeight : "bold", cursor : 'pointer'}} onClick={() => navigate("/about_us")}>
                About Us
            </NavItem>
            
            <NavItem className="mx-3 mt-2 text-uppercase" style={{fontSize : "12px", color : "rgb(29, 33, 141)",  fontWeight : "bold", cursor : 'pointer'}}
             onClick={() => navigate("/apply_here")}>
              
                Apply Here
              
            </NavItem>

             <NavItem 
             className="mx-3 mt-2 text-uppercase" 
             style={{fontSize : "12px", color : "rgb(29, 33, 141)",  
             fontWeight : "bold", cursor : 'pointer'}}
             onClick={() => navigate("/hire_a_dev")}>
              
                Hire A Dev
              
            </NavItem>
<NavItem>

            <UncontrolledDropdown group>
  <Button size="sm" className="text-uppercase"
  style={{ color : "rgb(29, 33, 141)",  
             fontWeight : "bold", fontSize : "12px",
             backgroundColor : "white"}}>
    Product Academy
  </Button>
  <DropdownToggle
    caret
    color="dark"
  />
  <DropdownMenu>

    <DropdownItem   onClick={() => navigate("/product_design")}>
      Product Design
    </DropdownItem>

    <DropdownItem       onClick={() => navigate("/data_science")}>
      Data Science
    </DropdownItem>

    <DropdownItem       onClick={() => navigate("/product_management")}>
      Product Management
    </DropdownItem>

  </DropdownMenu>
</UncontrolledDropdown>
</NavItem>
          </Nav>
        </Collapse>
        
      </Navbar>
    </>
  );
}

export default Index;