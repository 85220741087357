import React from 'react';
import {Row, Container, Button, Col} from "reactstrap"
import { AiOutlineArrowRight } from "react-icons/ai";
import {useNavigate} from "react-router-dom"

const Index = () => {
	const navigate = useNavigate()
	return (
		<>
<Row className="text-center p-5" style={{marginBottom : "150px", backgroundColor : 'rgb(27, 34, 140)', color : 'white'}}>
		<Col md={12}  xs={12} sm={12}>
			<Container>

		<h3 className="text-uppercase" style={{marginBottom : '12px', fontWeight : "bold"}}>
		What we prefer</h3>
		
		<p>
		Only candidate with zero experience or basic knowledge and fresh graduate can apply
</p>
</Container>
</Col>
</Row>

		<Row style={{margin : "105px 0 80px 0"}}>
		<Container>
		<center>
			<h3 className="text-uppercase" style={{color : "rgb(29, 33, 141)",  marginBottom : '12px', fontWeight : "bold"}}>
		Ready to become a UDev?</h3>
		


		<Button size="lg" style={{backgroundColor : "rgb(29, 33, 141)", 
		border : "none", fontSize : "13px"}} className="p-3" onClick={() => navigate("/apply_here")}>Apply Now <AiOutlineArrowRight /></Button>

		</center>
		</Container>
		</Row>
		</>
	)
}

export default Index