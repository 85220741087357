import React from 'react';
import {Row, Col, Container} from "reactstrap"
import { FaChalkboardTeacher, FaCoins } from "react-icons/fa";
import { MdOutlineWork } from "react-icons/md";

const Index = () => {
	return (
		<div>
		<Container style={{marginBottom : "150px"}}>
		<h3  style={{color : "rgb(29, 33, 141)", fontWeight : 'bold'}} className="text-uppercase mb-4 text-center">
		We have got you covered </h3>
		<Row className="text-center">
		<Col md={4}  xs={12} sm={12}  className="mb-4 card-body">
<center>
	<FaChalkboardTeacher style={{backgroundColor : "rgb(29, 33, 141)"}} className=" p-1 rounded" color="white"  size="30px"/>
	</center>
	<h4 style={{fontWeight :'bold', color : "rgb(29, 33, 141)"}} className="text-uppercase mt-2"  >What we train</h4>
  <p style={{color : "rgb(103, 118, 139)"}}>Deep focus on the most needed expertise in tech </p>
  </Col>

	<Col md={4}  xs={12} sm={12} className="mb-4  card-body">
  <MdOutlineWork style={{backgroundColor : "rgb(29, 33, 141)"}} className=" p-1 rounded" color="white"  size="30px"/>
  <h4 style={{fontWeight : 'bold', color : "rgb(29, 33, 141)"}} className="text-uppercase mt-2"  >Job placement </h4>
  <p style={{color : "rgb(103, 118, 139)"}}>We assist you through in-training, mentorship, internship, and job placement </p>
</Col>

<Col md={4}  xs={12} sm={12} className=" card-body">
  <FaCoins style={{backgroundColor : "rgb(29, 33, 141)", color : "white"}} className=" p-1 rounded" color="white"  size="30px" />
  <h4 style={{fontWeight : 'bold', color : "rgb(29, 33, 141)"}} className="text-uppercase mt-2"  >Flexible Tuition fee</h4>
  <p style={{color : "rgb(103, 118, 139)"}}>We offer flexible tuition fee pay after you have gotten a job</p>
  </Col>

		
		</Row>
				</Container>
				</div>
	)
}

export default Index