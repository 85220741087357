import React from 'react';
import Section1 from "./Section1"
import OurMissionCont from "./OurMissionCont"
import DiscoverOurProgram from "./DiscoverOurProgram"

const Index = () => {
	return (
		<>
<Section1 />
<OurMissionCont />
<DiscoverOurProgram />
		</>
	)
}

export default Index