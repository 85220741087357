import React, {useEffect} from 'react';
import {Row, Col, Button, Container} from "reactstrap"
import {useNavigate} from "react-router-dom"
import ProductManagement from "../../../../assets/images/project manager.jpg"
import LearningModel from "../../../../assets/images/learning-m.png"
import TrainingMethodology from "../../../../assets/images/training-methodology.png"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import { RiErrorWarningLine } from "react-icons/ri";

const Index = () => {
	const navigate = useNavigate()

	useEffect(() => {
  window.scrollTo(0, 0)
}, [])

	return (
		<>
		<Container>
		<Row style={{marginTop : "105px"}} className="mb-5">
		<Col md={6} xs={12} sm={12}>
			<h2 className="text-uppercase" style={{color : "rgb(29, 33, 141)",  fontWeight : "bold"}}>
			Product Management
			</h2>

			<h5 style={{color : "rgb(103, 118, 139)"}}>
			Elevate your Product Management Skills with our comprehensive  courses. 
			 </h5>
				<br />
			 <p style={{color : "rgb(103, 118, 139)", fontSize : "15px", textAlign : "justify"}}> 
			 This course offers in-depth knowledge on building a product from ideation to finish, customer and market research. 
			 Read to become a product manager  just press the button.
 </p>
			
				<br />
			<Button size="lg" style={{backgroundColor : "rgb(29, 33, 141)", 
			border : "none", fontSize : "15px", width : '30%'}} 
			className="mx-1 p-2" onClick={() => navigate("/apply_here")}>Apply Now 
			</Button>

			<Button size="lg" 
			style={{backgroundColor : "rgb(37, 49, 60)", 
			border : "none", fontSize : "15px", width : '30%'}} 
			className="p-2" 
			onClick={() => navigate("/apply_here")}>
			Chat Us 
			</Button>
			<br />
			<br />

			<p style={{fontSize : '14px'}} className="mr-1">
			<RiErrorWarningLine className="mb-1" 
			style={{color : "rgb(200, 8, 9)",
			 fontSize : '16px', fontWeight : "bold", }}/>{" "}
					Program starts November 2023</p>
		</Col>

		<Col md={6} xs={12} sm={12}>	
		
		<img src={ProductManagement} alt="ii" width="100%"
		height ="100%" className="shadow gap-2 mx-4 " 
		style={{borderTopLeftRadius : "1%", 
		borderTopRightRadius : "1%", 
		borderBottomLeftRadius : "50%", borderBottomRightRadius : "50%"}}/>
		
		</Col>
		</Row>
		</Container>
		<br />


<Container>
<div className="my-5">

<ScrollAnimation animateIn="animate__bounceInDown">
<h3 style={{color : "rgb(29, 33, 141)", fontWeight : 'bold', textAlign : "center"}} 
className="text-uppercase mb-2">  Learn today with us</h3>
</ScrollAnimation>

<ScrollAnimation animateIn="animate__fadeInUp">
<p style={{textAlign : "center", color : "rgb(103, 118, 139)"}} className="mb-5">
All courses are project-based and taught by industry leaders to give you the industry experience needed to become the best in digital production creation. We provide one-on-one career coaching throughout the process and connect you with
 potential employers to get you hired upon graduation. 
</p>
</ScrollAnimation>

</div>
</Container>

<div style={{backgroundColor : "rgb(240, 240, 243)", 
marginBottom : "90px", marginTop : "150px"}} 
	className=" p-5">
<h3 style={{color : "rgb(29, 33, 141)", 
fontWeight : 'bold'}} 
className="text-uppercase mb-5 text-center mt-4">
Our learning process
</h3>


<Row style={{width : "70%", margin : "auto"}}>
<Col md={6} className="text-center">
<img src={LearningModel} style={{width : "10%"}} alt="logo"/>
<h5>Learning Model</h5>	
<p style={{fontSize : "14px", 
color : "rgb(103, 118, 139)"}}>12 weeks in person hands on learning and training</p>
</Col>

<Col md={6} className="text-center">
<img src={TrainingMethodology} alt="logo" style={{width : "10%"}} className="mb-4"/>
<h5>Training Methodology</h5>	
<p  style={{fontSize : "14px", 
color : "rgb(103, 118, 139)"}}>Learn through real-life problems and apply it
 In solving related business problems
</p>
</Col>
</Row>
</div>

<Container className="my-5">
<h6 className="text-uppercase mb-4" 
style={{color : "rgb(200, 8, 9)"}}>
This beginners program is set to run for 3 months</h6>

<Row>
<Col md={4}>
<h5>Course Title </h5> 
<p style={{fontSize : '14px'}}>Product Management</p>
</Col>

<Col md={4}>
<h5>Course Duration</h5> 
 <p style={{fontSize : '14px'}}>12 weeks (Weekends)</p>
</Col>

<Col md={4}>
<h5>Classes</h5> 
<p style={{fontSize : '14px'}}>Physical / Comprehensive Curriculum </p>
</Col>
</Row>
</Container>

<br />
<Container>
<h3 style={{color : "rgb(29, 33, 141)", fontWeight : 'bold'}} 
className="text-uppercase mb-3 text-center mt-5">Course Outline</h3>
  
  <ScrollAnimation animateIn="animate__fadeInUp">
<div className="card shadow mb-4">
<div className="card-body">
<h6 className="text-uppercase" style={{color : "rgb(200, 8, 9)"}}>Module 1</h6>
 <p style={{fontSize : '14px', textAlign : 'justify'}}>
 Foundation and principles of product management, Master the art of product strategy, develop a deep understanding of 
 market research and customer insights. </p>
 </div>
</div>
    </ScrollAnimation>
    

  <ScrollAnimation animateIn="animate__fadeInUp">
<div className="card shadow mb-4">
<div className="card-body">
<h6 className="text-uppercase" style={{color : "rgb(200, 8, 9)"}}>Module 2</h6>
<p style={{fontSize : '14px', textAlign : 'justify'}}>
Become an Agile and Scrum master, learn to lead cross functional teams, manage sprint, learn how 
to optimize product development processes.</p>
</div>
</div>
    </ScrollAnimation>
    

  <ScrollAnimation animateIn="animate__fadeInUp">
<div className="card shadow mb-4">
<div className="card-body">
<h6 className="text-uppercase" style={{color : "rgb(200, 8, 9)"}}>Module 3</h6>
<p style={{fontSize : '14px', textAlign : 'justify'}}>
Product life cycle management, learn how to navigate the entire product life cycle, 
from ideation to launch and ongoing optimization.
</p>
</div>
</div>
    </ScrollAnimation>
    

  <ScrollAnimation animateIn="animate__fadeInUp">
<div className="card shadow mb-4">
<div className="card-body">
<h6 className="text-uppercase" style={{color : "rgb(200, 8, 9)"}}>Module 4</h6>
 <p style={{fontSize : '14px', textAlign : 'justify'}}>
 Learn design thinking principles, gain insight on how user research is conducted.</p>
</div>
</div>
    </ScrollAnimation>
    

  <ScrollAnimation animateIn="animate__fadeInUp">
<div className="card shadow mb-4">
<div className="card-body">
<h6 className="text-uppercase" style={{color : "rgb(200, 8, 9)"}}>Module 5</h6>
 <p style={{fontSize : '14px', textAlign : 'justify'}}>
 Learn how to be creative in the process of identifying opportunities, solving problems and handling user feedback.

 </p>
</div>
</div>
    </ScrollAnimation>
    </Container>

    <div className="p-3 my-5" 
	style={{width : "70%", margin : "auto"}}>
	<h4 style={{color : "rgb(29, 33, 141)", 
fontWeight : 'bold'}} 
className="text-uppercase mb-5 text-center mt-5">Cost</h4>
		<Row>
<Col md={6}>
<div className="card mb-4">
<div className="card-body">
<h5 style={{color : "rgb(103, 118, 139)"}} 
className="mb-3">
Outright Payment</h5>
<h1 className="mb-3 mt-4" style={{fontWeight : "bold", 
color : "rgb(29, 33, 141)"}}>
N250,000</h1> 	                         

<p className="mt-3" style={{fontSize : "14px", 
color : "rgb(103, 118, 139)"}}>
Pay your fees upfront, no other 
charges come up during your learning
</p>
</div>
</div>
</Col>

<Col md={6}>
<div className="card">
<div className="card-body">
<h5 style={{color : "rgb(103, 118, 139)"}} >
Part Payment</h5>

<h6 style={{color : "rgb(103, 118, 139)"}} 
className="mb-3">(Pay 70% Upfront) </h6>
<h1 className="mb-3 mt-4" style={{fontWeight : "bold", 
color : "rgb(29, 33, 141)"}}> N175,000</h1> 

<p className="mt-1" style={{fontSize : "14px", 
color : "rgb(103, 118, 139)"}}>  Complete your payment during the program
</p>
</div>
</div>
</Col>
</Row>
</div>
   
		</>
	)
}

export default Index

