import React from 'react';
import Hero from "./Hero"
import Section1 from "./Section1"
import Section2 from "./Section2"
import Section3 from "./Section3"
import ApplyNow from "./ApplyNow"
import Section6 from "./Section6"
import CourseOverview from "./CourseOverview"
import Product from "./Product"
import {Helmet} from "react-helmet-async"

const Index = () => {
	return (
		<>
		<Helmet>
			<title>4U Academy</title>
			<meta
      name="description"
      content="4u Academy is a software engineering and job placement platform that trains and connects young people with long-term jobs."
    />

        <link rel="canonical" href="/" />
		</Helmet>

		<Hero />
		<Section1 />
		<Section2 />
		<Product />
		<CourseOverview />
		<Section3 />
		<ApplyNow />
		<Section6 />
		</>
	)
}

export default Index
