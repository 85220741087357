import React from 'react';
import {Row, Col} from "reactstrap"

const Index = () => {
	return (

<Row 
style={{marginBottom : "150px", marginTop : '105px', backgroundColor : 'rgb(27, 34, 140)', color : 'white'}} className="p-3">
			
				<Col md={2}  xs={12} sm={12}>
				</Col>
				<Col md={8}  xs={12} sm={12} className="text-center">
		<h3 style={{fontWeight : 'bold'}} className="text-uppercase">What we give you</h3>
		
  <p> Practice the most in-demand skills with the best talents.</p>
  <p> 9 months training at our beautiful Campus.</p>
  <p> We help you get hired.</p>

</Col>
<Col md={2}  xs={12} sm={12}>
</Col>
		</Row>
		
	)
}

export default Index