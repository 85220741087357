import React from 'react';
import {Row, Col, Container} from "reactstrap"

const Index = () => {
	return (
		<Row className="text-center p-5" style={{marginBottom : "150px", backgroundColor : 'rgb(27, 34, 140)', color : 'white'}}>
		<Col md={12}  xs={12} sm={12}>
			<Container>
			<h4 style={{fontWeight : 'bold'}} className="mb-3 text-uppercase" >Why we do What we do?</h4>
			<p className="mt-2">
			It is well known that our traditional educational system requires major reform. 
			The system has long been broken with outdated curriculums which renders our graduate from higher 
			institute irrelevant in today’s hyper modern world. Too often, in this part of the 
			country people are left with no choices and opportunities are way out of their sight. 
			We believe our society need’s better ways, people deserve better. That’s why we started the 4U 
			Institute. This comes down to our big goal to help the society have new 
			skills for the 21st century, 
			have good paying jobs & explore diversity 
			for good & better. We teach the skills employers want and we go the extra mile to help you 
				explore every other component of becoming 
				outstanding. We are pioneering a new dawn of prosperity.
			 We’re creating the work force of the future by harvesting great human potential.</p>
			</Container>
		</Col>
		</Row>
	)
}

export default Index