import React,{useState} from 'react';
import {Card,CardBody,Button,Form,Input,Label,FormGroup,Row,Col,Spinner} from "reactstrap"
import {apiURL} from '../../redux/actions/api'
import {toast,ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Index=() => {

  const [form,setForm]=useState({
    firstname: '',
    lastname: '',
    middlename: '',
    dob: '',
    email: '',
    phone: '',
    gender: '',
    course : ''
  })

  const [loading,setLoading]=useState(false)

  const handleChange=({target: {name,value}}) => setForm((p) => ({...p,[name]: value}))

  const submitApplication=(e) => {
    const {
      firstname,
      lastname,
      dob,
      email,
      phone,
      gender, 
      course}=form

    e.preventDefault()

    if(firstname===''||
      lastname===''||
      dob===''||
      email===''||
      phone===''||
      gender==='' || 
      course === '') {
      toast.warning('please complete the form',{
        position: toast.POSITION.TOP_CENTER
      })
    }

    else {
      setLoading(true)
      fetch(`${apiURL}/submit_application_student`,{
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          form
        }),
      })
        .then(raw => raw.json())
        .then((resp) => {
          // console.log("resp...", resp)
          if(resp&&resp.success) {
            toast.success(resp.message,{
              position: toast.POSITION.TOP_CENTER
            })

            setForm({
              firstname: '',
              lastname: '',
              middlename: '',
              dob: '',
              email: '',
              phone: '',
              gender: '',
              course : ''
            })
            setLoading(false)
          }
          else {
            toast.error(resp.message,{
              position: toast.POSITION.TOP_CENTER
            })
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
          toast.error(err.message,{
            position: toast.POSITION.TOP_CENTER
          })
        })
    }

  }

  return (
    <Row style={{marginBottom: "150px"}} className="mt-5">
      <ToastContainer />
      <Col md={3}></Col>
      <Col md={6}>
        <Card className="shadow">
          <CardBody>

            <h3 className="text-center text-uppercase" style={{color: "rgb(27, 34, 140)",fontWeight: 'bold'}}>Apply Here </h3>
            <Form>
              <FormGroup>
                <Label>Firstname</Label>
                <Input
                  type="text"
                  required
                  placeholder="firstname"
                  name="firstname"
                  value={form.firstname}
                  onChange={handleChange}

                />
              </FormGroup>

              <FormGroup>
                <Label>Middlename</Label>
                <Input
                  type="text"
                  required
                  placeholder="middlename"
                  name="middlename"
                  value={form.middlename}
                  onChange={handleChange}
                />
              </FormGroup>


              <FormGroup>
                <Label>Lastname</Label>
                <Input
                  type="text"
                  required
                  placeholder="lastname"
                  name="lastname"
                  value={form.lastname}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  required
                  placeholder="email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>Phone</Label>
                <Input
                  type="text"
                  required
                  placeholder="phone number"
                  name="phone"
                  value={form.phone}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>Date of Birth</Label>
                <Input
                  type="date"
                  required
                  placeholder="date of birth"
                  name="dob"
                  value={form.dob}
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>Gender</Label>
                <Input
                  type="select"
                  required
                  name="gender"
                  value={form.gender}
                  onChange={handleChange}
                >
                  <option value="">Select---</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Input>
              </FormGroup>

                <FormGroup>
                <Label>Course</Label>
                <Input
                  type="select"
                  required
                  name="course"
                  value={form.course}
                  onChange={handleChange}
                >
                  <option value="">Select---</option>
                  <option value="product design">Product Design</option>
                  <option value="data science">Data Science</option>
                  <option value="product management">Product Management</option>
                </Input>
              </FormGroup>

            </Form>

            <center>
              <Button size="lg" style={{
                backgroundColor: "rgb(27, 34, 140)",
                border: "none",fontSize: "13px"
              }} className="mb-4 p-3"
                onClick={submitApplication} disabled={loading}>{
                  loading&&<Spinner size="sm" />
                } Submit </Button>
            </center>
          </CardBody>
        </Card>
      </Col>
      <Col md={3}></Col>
    </Row>
  )
}

export default Index
