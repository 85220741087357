import React from 'react';
import {  Container, Row, Col, NavLink} from "reactstrap"
import "./footer.css"
import logo from "./logo.jpg"
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram, AiFillTwitterCircle } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";




const Index = () => {
	return (
		<div style={{backgroundColor : 'rgb(37, 49, 60)', color : 'white', minHeight : '250px'}}>
<Container >
    {//<div className="d-flex justify-content-between">
}
<Row>
<Col md={4} sm={6} xs={6}>
    <div style={{marginTop : '50px'}} className="footer_p">
    <img src={logo} width="100px" alt="logo" className="mb-3"/>
    <p>No. 7 Khadija House opposite state house of assembly, 
    <br />
    Zaria Road,</p>
    <p>Kano</p>
    </div>
    </Col>


    <Col md={4} sm={6} xs={6}>
    <div style={{marginTop : '50px'}} className="footer_p">
<h5>Contact Us</h5>
    <p>Phone number: +2348144707410</p>
    <p>WhatsAPP: +2348144707410</p>
    <p>Email: info@4uacademyafrica.com</p>
    </div>
</Col>

<Col md={4} sm={6} xs={6} className="mb-2">
    <div style={{marginTop : '50px'}} className="footer_p">
<h5>Connect with Us</h5>

           <NavLink                          
          href="https://web.facebook.com/profile.php?id=100090661086102"
          target="_blank" 
          style={{display : 'inline'}}
        >
            <BsFacebook style={{cursor : 'pointer', color : 'rgb(160, 165, 170)'}}          
             className="mx-1"/>
             </NavLink>

             

                        <NavLink                          
          href="https://www.instagram.com/4uacademyafrica/"
          target="_blank" 
          style={{display : 'inline'}}
        >
            <AiFillInstagram style={{cursor : 'pointer', color : 'rgb(160, 165, 170)'}}          
             className="mx-1"/>
             </NavLink>

                    

                        <NavLink                          
          href="https://wa.me/c/2348144707410"
          target="_blank" 
          style={{display : 'inline'}}
        >
            <IoLogoWhatsapp style={{cursor : 'pointer', color : 'rgb(160, 165, 170)'}}          
             className="mx-1"/>
             </NavLink>

              <NavLink                          
          // href="https://wa.me/c/2348144707410"
          target="_blank" 
          style={{display : 'inline'}}
        >
            <AiFillTwitterCircle style={{cursor : 'pointer', color : 'rgb(160, 165, 170)'}}          
             className="mx-1"/>
             </NavLink>

    </div>
</Col>

    </Row>

    {// <p className="mt-5 mb-1">&#169; Copyright Lisafi.com</p>
}

	</Container>
</div>
	)
}

export default Index