import React, {useState} from 'react';
import {Card, CardBody,  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem, Row, Col } from "reactstrap"
const Index = () => {
    const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
	return (
		<Row style={{marginBottom : "150px"}}>
<Col md={2}></Col>
<Col md={8}>
		<Card className="shadow">
  <CardBody>
  <h3 className="mb-3 text-center text-uppercase" style={{color : "rgb(29, 33, 141)", fontWeight : 'bold'}}>Got your questions answered</h3>
 <div>
      <Accordion open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1"><strong> What 4U does?</strong></AccordionHeader>
          <AccordionBody accordionId="1">          
4U is a software engineering institute that provides hands on training and mentorship to become a junior developer with high paying career in tech. 
<br />
We are solving the problem of youth unemployment and bridging the talent gap for millennial across Nigeria.

          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2"><strong>How do I become a student?</strong></AccordionHeader>
          <AccordionBody accordionId="2">
            Apply from the portal, we’ll take it up from there.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3"><strong>Who can apply?</strong></AccordionHeader>
          <AccordionBody accordionId="3">
            <ul>
             <li>Any person between the age of 17 to 30 years is eligible to apply.</li>
<li> Hungry to learn new stuffs and to explore </li>
<li> Completed either Secondary school, National Diploma, HND, Degree or Master’s Degree</li>
</ul>
          </AccordionBody>
        </AccordionItem>
         <AccordionItem>
          <AccordionHeader targetId="4"><strong>How long is the training program?</strong></AccordionHeader>
          <AccordionBody accordionId="4">
The training is a 9 months intensive in person program.
          </AccordionBody>
        </AccordionItem>

         <AccordionItem>
          <AccordionHeader targetId="5"><strong>What do I expect in the program?</strong></AccordionHeader>
          <AccordionBody accordionId="5">
Students are entitled to learning materials, laptop, internet access, and other support that will be important in their journey to excel.    
          </AccordionBody>
        </AccordionItem>

         <AccordionItem>
          <AccordionHeader targetId="6"><strong>How much does the program cost?</strong></AccordionHeader>
          <AccordionBody accordionId="6">
At the moment, the fee is N1,200,000 and covers learning materials and laptop.
          </AccordionBody>
        </AccordionItem>

         <AccordionItem>
          <AccordionHeader targetId="7"><strong>Is there financial aid for the program? </strong></AccordionHeader>
          <AccordionBody accordionId="7">
Students who cannot afford tuition fees, you can study with us and pay later through our student loan which covers 100% of the training costs.
          </AccordionBody>
        </AccordionItem>

         <AccordionItem>
          <AccordionHeader targetId="8"><strong>I need to talk with someone, who do I talk to?</strong></AccordionHeader>
          <AccordionBody accordionId="8">
We are ready to hear from you, send us a mail via info@4uacademyafrica.com
          </AccordionBody>
        </AccordionItem>




                 <AccordionItem>
          <AccordionHeader targetId="9"><strong>Is the training remote?</strong></AccordionHeader>
          <AccordionBody accordionId="9">
All classes will take place at our for 4u campus in person
          </AccordionBody>
        </AccordionItem>

                         <AccordionItem>
          <AccordionHeader targetId="10"><strong>What next, after registration? </strong></AccordionHeader>
          <AccordionBody accordionId="10">
Once you have successfully registered, you will be moved to the next stage where you will be required to take some assessments. Wishing you the very best.
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>     
          
  </CardBody>
</Card>
</Col>
<Col md={2}></Col>
</Row>
	)
}

export default Index