import React from 'react';
import logo2 from "./images/Banner1.jpeg"
import {Row, Col, Container} from "reactstrap"
// import {useNavigate} from "react-router-dom"
// import { AiOutlineArrowRight } from "react-icons/ai";
import Calender from "../../../assets/images/calender.png"
// import { Link } from 'react-router-dom';
// import ProductAcademy from "../Product"
const Index = () => {
	// const navigate = useNavigate()
	return (
		<Container>
		<Row style={{marginTop : "105px"}}>
		<Col md={6} xs={12} sm={12}>
			<h2 className="text-uppercase" style={{color : "rgb(29, 33, 141)",  marginBottom : '12px', fontWeight : "bold"}}>
			Kickstart your career <br />in tech today.
			</h2>

			<br />
			<h4 style={{color : "rgb(103, 118, 139)"}}>Get the skills you need to become a software engineer in today’s global market.</h4>
			
				<br />
			{// <Button size="lg" style={{backgroundColor : "rgb(29, 33, 141)", 
			// border : "none", fontSize : "13px", width : '30%'}} 
			// className="my-2 p-2" onClick={() => navigate("/apply_here")}>Apply Now 
			// <AiOutlineArrowRight /></Button>
		}
			<p style={{color : "rgb(100, 128, 119)",
			 fontSize : '15px', fontWeight : "bold"}} className="mr-1">
			 New courses avaliable on Product Academy 
			 {" "}
	{// 		 <span style={{border : '1px solid blue', 
	// borderRadius : "5px", cursor : "pointer"}} 
	// className="p-1 "> Click here  </span>
}
			 </p>
			<p style={{color : "rgb(200, 8, 9)",
			 fontSize : '14px'}} className="mr-1">
			{// < AiFillCalendar className="mb-1" />
		}
		<img src={Calender} alt="logo" 
style={{width : "4%"}} className="mb-1" /> {" "}
			 Application is currently closed for both web and backend development course</p>
			<br />
			<br />
		</Col>

		<Col md={6} xs={12} sm={12}>	
		<img src={logo2} alt="ii" width="100%" height ="100%" className="shadow rounded" />
		</Col>
		</Row>

		<br />
		
		</Container>
	)
}

export default Index