// import './App.css';
import Navbar from "./Component/Navbar"
import Footer from "./Component/Footer"
import Home from "./Component/Home"
import AboutUs from "./Component/AboutUs"
import ApplyHere from "./Component/ApplyHere"
import HireADev from "./Component/HireADev"
import { Routes, Route } from "react-router-dom";
import ProductDesign from "./Component/Home/Product/ProductDesign"
import ProductManagement from "./Component/Home/Product/ProductManagement"
import DataScience from "./Component/Home/Product/DataScience"


function App() {
  return (
    <>
      <Navbar />
      <Routes>
 {// <Route path="/" element={<Navigate replace to="4uacademyafrica.com" />} />
}
 <Route path="/"  element={<Home />}  /> 
 <Route path="/about_us"  element={<AboutUs />} /> 
 <Route path="/apply_here"  element={<ApplyHere />} /> 
 <Route path="/hire_a_dev"  element={<HireADev />} /> 
 <Route path="/product_design"  element={<ProductDesign />} /> 
 <Route path="/data_science"  element={<DataScience />} /> 
 <Route path="/product_management"  element={<ProductManagement />} /> 
 </Routes>

      <Footer />
    </>
  );
}

export default App;
