import React from 'react';
import {Row, Container, Col} from "reactstrap"
import backendlogo from "../Backend pic.png"
import frontendlogo from "../frontend pic.jpg"

const Index = () => {
	return (
		<div>
			
				<Container>
	<Row style={{marginBottom : "150px", marginTop : '150px', color : 'white'}}>
			
		
	<Col md={6}  xs={12} sm={12}>
<h3 style={{color : "rgb(29, 33, 141)", fontWeight : "bold"}} className="text-uppercase">
		FullStack Web Development</h3>
<p style={{color : "rgb(103, 118, 139)"}}>An in-depth in person class web developer course to prepare for a 
successful career in tech. Javascript, HTML, CSS, Nodejs, React, Redux, Python, SQL.</p>


<h4 style={{color : "rgb(29, 33, 141)",  marginBottom : '12px', fontWeight : "bold"}}>OVERVIEW</h4>
<p style={{color : "rgb(103, 118, 139)"}}>As a developer or engineer, you 
work on a team to build websites or web apps that solve problems & make a 
difference. Developers and engineers continue to be in high demand to be in 
high demand as the tech industry grows.</p>
</Col>

<Col md={6}  xs={12} sm={12}>
		<img src={frontendlogo} alt="logo" width="80%" className="shadow rounded"/>
		</Col>
</Row>



<Row style={{marginBottom : "150px"}}>

<Col xs={{ span: 12, order: 2 }} sm={{ span: 12, order: 2 }}  md={{ span: 6, order: 2 }}>
		<img src={backendlogo} alt="logo" width="80%" className="shadow rounded"/>
		</Col>

<Col xs={{ span: 12, order: 1 }} sm={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
<h3 style={{color : "rgb(29, 33, 141)",  marginBottom : '12px', fontWeight : "bold"}} className="text-uppercase">
Backend Web Development</h3>
<p style={{color : "rgb(103, 118, 139)"}}>Java foundation, Java programming, 
Testing Software, Engineering, Data Structures Algorithms, Concurrency DynamoDB</p>


<h4 style={{color : "rgb(29, 33, 141)",  marginBottom : '12px', fontWeight : "bold"}}>OVERVIEW</h4>
<p style={{color : "rgb(103, 118, 139)"}}>As a back end developer, you’ll design, 
build and maintain infrastructure support the user interfaces and user experiences
built by front end developers.</p>
</Col>
		</Row>
		</Container>
		</div>
	)
}

export default Index