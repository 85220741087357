import React, {useState} from 'react';
import {Card, CardBody, Button, Form, Input, Label, FormGroup, Row, Col, Spinner } from "reactstrap"
import { apiURL } from '../../../redux/actions/api'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Index = () => {

  const [form, setForm] = useState({
    firstname : '',
    lastname : '',
    middlename : '',
    email : '',
    phone : '',
    title : '',
    company_name : ''
  })
  
  const [loading, setLoading] = useState(false)
  
  const handleChange = ({ target: { name, value } }) => setForm((p) => ({ ...p, [name]: value }))

  const submitApplication = (e) => {
    const {
    firstname,
    lastname ,
    middlename ,
    email ,
    phone ,
    title, company_name } = form

    e.preventDefault()

    if ( firstname === '' ||
    lastname  === '' ||
    middlename  === '' ||
    title  === '' ||
    email  === '' ||
    phone  === '' ||
    company_name === '') {
toast.warning('please complete the form', {
        position: toast.POSITION.TOP_CENTER
      })
    }

    else{
    e.preventDefault()
    setLoading(true)
    fetch(`${apiURL}/submit_application_client`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          form
        }),
      })
        .then((raw) => raw.json({}))
        .then((resp) => {
        if (resp && resp.success) {
        toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER
      })

        setForm({
          firstname : '',
    lastname : '',
    middlename : '',
    email : '',
    phone : '',
    title : '',
    company_name : ''
        })
        setLoading(false)
      }
        else{
          toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER
      })
          setLoading(false)
        }
        })

        .catch((err) => {
          console.log(err)
            toast.error(err.message, {
        position: toast.POSITION.TOP_CENTER
      })
        })
      }
    
  }

	return (
    <>
    <ToastContainer />
		<Row style={{marginBottom : "150px"}}>
<Col md={3}></Col>
<Col md={6}>
		<Card className="shadow">
  <CardBody>

        <h3 className="text-center text-uppercase" style={{color : "rgb(27, 34, 140)", fontWeight : 'bold'}}>Hire A Dev </h3>
  <Form>
    <FormGroup>
    <Label>Firstname</Label>
    <Input
      type="text"
      required
      placeholder="firstname"
      name="firstname"
      value={form.firstname}
      onChange={handleChange}
    />
  </FormGroup>

  <FormGroup>
    <Label>Middlename</Label>
    <Input
      type="text"
      required
      placeholder="middlename"
      name="middlename"
      value={form.middlename}
      onChange={handleChange}
    />
  </FormGroup>

  <FormGroup>
    <Label>Lastname</Label>
    <Input
      type="text"
      required
      placeholder="lastname"
      name="lastname"
      value={form.lastname}
      onChange={handleChange}
    />
  </FormGroup>

    <FormGroup>
    <Label>Email</Label>
    <Input
      type="email"
      required
      placeholder="email"
      name="email"
      value={form.email}
      onChange={handleChange}
    />
  </FormGroup>

    <FormGroup>
    <Label>Phone</Label>
    <Input
      type="text"
      required
      placeholder="phone number"
      name="phone"
      value={form.phone}
      onChange={handleChange}
    />
  </FormGroup>

    <FormGroup>
    <Label>Title</Label>
    <Input
      type="text"
      required
      placeholder="title"
      name="title"
      value={form.title}
      onChange={handleChange}
    />
  </FormGroup>

<FormGroup>
    <Label>Company Name</Label>
    <Input
      type="text"
      required
      placeholder="company name"
      name="company_name"
      value={form.company_name}
      onChange={handleChange}
    />
  </FormGroup>

  </Form>

<center>
      <Button size="lg" style={{backgroundColor : "rgb(27, 34, 140)", 
      border : "none", fontSize : "13px"}} className="mb-4 p-3" 
      onClick={submitApplication} disabled={loading}>{
       loading && <Spinner size="sm" />
       } Submit </Button>
      </center>
  </CardBody>
</Card>
</Col>
<Col md={3}></Col>
</Row>
</>
	)
}

export default Index