import React from 'react';
import {Row, Container} from "reactstrap"

const Index = () => {
	return (
		<Row style={{margin : "105px 0 70px 0"}}>
		<Container>
		<center>
		<h3 style={{color : "rgb(27, 34, 140)",  marginBottom : '12px', fontWeight : "bold"}} className="text-uppercase">
		Our Mission</h3>
		
		<p style={{color : "rgb(103, 118, 139)"}}>Bridging the talent gap in the tech ecosystem across Africa.</p>
		</center>
		</Container>
		</Row>
	)
}

export default Index