import React from "react"
import {useNavigate} from "react-router-dom"
import {Container, Button} from "reactstrap"
import ProductDesign from "../../../assets/images/product design (2).jpg"
import ProductMarketing from "../../../assets/images/project marketing.jpg"
import ProductManagement from "../../../assets/images/project manager.jpg"
// import { AiOutlineArrowRight } from "react-icons/ai";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const Index = () => {
const navigate = useNavigate()
	return (
	<div id="section1" style={{backgroundColor : "rgb(240, 240, 243)", marginBottom : "150px"}} 
	className="mt-4 p-5">
		<Container>
		<div className="row row-cols-1">
		<div className="col">
<h3 style={{color : "rgb(29, 33, 141)", fontWeight : 'bold', textAlign : "center"}} 
className="text-uppercase mb-2">Product Academy</h3>
<p style={{textAlign : "center", color : "rgb(103, 118, 139)"}} className="mb-5">
Begin your career in product design, 
product marketing and project management, 
these courses are taught by top notch industry 
tech leaders to help you boost your career in tech. 
We prepare you with the knowledge needed to stay at
 the top of the industry.
</p>
</div>
</div>
<div className="row row-cols-1 row-cols-md-3 g-4">
  <div className="col">
  <ScrollAnimation animateIn="animate__fadeInUp">
    <div className="card shadow">
      <img src={ProductDesign} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title text-uppercase text-center" style={{fontWeight : 'bold'}}>Product Design (UI/UX)</h5>
        <p className="card-text text-center" 
        style={{fontSize : "14px", 
        textAlign : "justify", color : "rgb(103, 118, 139)"}}>
This course will take you through
the fundamentals of product design
and give you the skills needed to become a product  designer.

        </p>
        <center>
        <Button size="lg" 
        style={{backgroundColor : "rgb(29, 33, 141)", 
			border : "none", fontSize : "13px"}} 
			className="my-2 p-2" 
			onClick={() => navigate("/product_design")}
			>View Program
			 </Button>
       </center>
      </div>
    </div>
    </ScrollAnimation>
  </div>

  <div className="col">
    <ScrollAnimation animateIn="animate__fadeInUp">
    <div className="card shadow">
      <img src={ProductMarketing} className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title text-uppercase text-center" 
        style={{fontWeight : 'bold'}}>Data Science</h5>
        <p className="card-text text-center" style={{fontSize : "14px", textAlign : "justify", color : "rgb(103, 118, 139)"}}>
This course will teach you the basic principle 
  of data science through a
 strategic practical approach.
         </p>
<center>
         <Button size="lg" style={{backgroundColor : "rgb(29, 33, 141)", 
			border : "none", fontSize : "13px"}} 
			className="my-2 p-2" 
			onClick={() => navigate("/data_science")}
			>View Program
			 </Button>
       </center>
      </div>
    </div>
    </ScrollAnimation>
  </div>

  <div className="col">
    <ScrollAnimation animateIn="animate__fadeInUp">
    <div className="card shadow">
      <img src={ProductManagement} height="100%"  className="card-img-top" alt="..." />
      <div className="card-body">
        <h5 className="card-title text-uppercase text-center" style={{fontWeight : 'bold'}}>Product Management</h5>
        <p className="card-text text-center" style={{fontSize : "14px", textAlign : "justify", color : "rgb(103, 118, 139)"}}>
This course provide you with the essential of
product management skills and is design for those 
who want to start a career in product management.
        </p>
<center>
        <Button size="lg" 
        style={{backgroundColor : "rgb(29, 33, 141)", 
			border : "none", fontSize : "13px"}} 
			className="my-2 p-2" 
			onClick={() => navigate("/product_management")}
			>View Program
			 </Button>
       </center>
      </div>
    </div>
    </ScrollAnimation>
  </div>
</div>


		</Container>
		</div>
	)
}

export default Index