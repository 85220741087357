import React from 'react';
import {Row, Col, Container} from "reactstrap"

const Index = () => {
	return (
		<Container>
		<h3 style={{color : "rgb(29, 33, 141)", fontWeight : 'bold'}} className='text-center text-uppercase'>Course Overview</h3>		
		
		<Row>
		<Col md={5} xs={12} sm={12} style ={{marginBottom : "150px"}} className="card card-body mt-4">

		<h4 style={{color : "rgb(29, 33, 141)", fontWeight : 'bold'}} className="text-center text-uppercase">
		Web Development</h4>
		<hr />
		<p style={{color : "rgb(103, 118, 139)"}} className="text-center">Join our Full-stack web developement course</p>
		<hr />

		<p style={{color : "rgb(103, 118, 139)"}}>Learn the fundamentals of:</p>
		<ul>
  <li style={{color : "rgb(103, 118, 139)"}}>Frontend</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Backend servers</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Databases</li>
  <li style={{color : "rgb(103, 118, 139)"}}>API's and more</li>
</ul>

<hr />


<h4 style={{color : "rgb(29, 33, 141)", fontWeight : 'bold'}} className="text-uppercase">What You Could Be</h4>
	<hr />	
		<ul>
  <li style={{color : "rgb(103, 118, 139)"}}>Web developer</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Mobile App developer</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Software Engineer</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Full-stack developer</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Web designer</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Software Developer</li>
  <li style={{color : "rgb(103, 118, 139)"}}>Junior developer</li> 
  <li style={{color : "rgb(103, 118, 139)"}}>Java developer</li>
</ul>
		</Col>

				<Col md={1} xs={12} sm={12} style ={{marginBottom : "150px"}} className="mt-4">
				</Col>

		<Col md={5} xs={12} sm={12} style ={{marginBottom : "150px"}} 
		className="card card-body bg-dark text-white mt-4">
			
		<h4 style={{color : "white", fontWeight : 'bold'}} className="text-center text-uppercase">Backend Developement</h4>
		<hr />
		<p style={{color : "white"}}>What exactly does a backend developer do?
Designing,
Building,
Maintaining</p>
<p style={{color : "white"}}>Behind the scene infrastructure
to support the user front end interface.</p>

<hr />
		<ul>
  <li style={{color : "white"}}>You will learn working with databases.</li>
  <li style={{color : "white"}}>Building user interface.</li>
  <li style={{color : "white"}}>working with Clients during planning.</li>
</ul>


<hr />
<h4 style={{color : "white", fontWeight : 'bold'}} className="text-uppercase">What You Will Learn</h4>

<hr />		
		<ul>
  <li style={{color : "white"}}>Foundation of java</li>
  <li style={{color : "white"}}>Java programing</li>
  <li style={{color : "white"}}>Data structures & Algorithms</li>
  
</ul>
		</Col>

	</Row>
	</Container>
	)
}

export default Index