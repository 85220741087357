import React from 'react';
import image6 from "./How it works.jpg"
import {Row, Col, Card, CardBody, Container} from "reactstrap"


const Index = () => {
	return (
		<Container>
		<Row style={{marginBottom : "150px"}}>
		<h3 style={{color : "rgb(29, 33, 141)", fontWeight : 'bold'}} className="text-uppercase mb-5">How it works</h3>
		<Col  md={6} xs={12} sm={12}>
	

<h5><span className="rounded bg-dark p-1 text-white mx-1 lead">1</span>Application process</h5>
  <p style={{color : "rgb(103, 118, 139)"}}>Candidate apply to the 4u program</p>
  <h5><span className="rounded bg-dark p-1 text-white mx-1 lead">2</span>Study materials</h5>
  <p style={{color : "rgb(103, 118, 139)"}}>You will be provided with study materials to prepare for the assessment
</p>
<h5><span className="rounded bg-dark p-1 text-white mx-1 lead">3</span>In person Assessment</h5>
  <p style={{color : "rgb(103, 118, 139)"}}>candidates will take an assessment at our center to determine if they will be a good fit for the program.</p>
  
  <h5><span className="rounded bg-dark p-1 text-white mx-1 lead">4</span>Admission</h5>
  <p style={{color : "rgb(103, 118, 139)"}}>Successful candidate will be offered admission into the  program </p>

<h5><span className="rounded bg-dark p-1 text-white mx-1 lead">5</span>Training</h5>
<p style={{color : "rgb(103, 118, 139)"}}>We deliver an intensive 9 months program, during which students 
will learn Frontend software development, backend software development 
and Soft skills like communication, leadership and teamwork.</p>
                                      
<h5><span className="rounded bg-dark p-1 text-white mx-1 lead">6</span>Internship & Job Placement</h5>
<p style={{color : "rgb(103, 118, 139)"}}>We offer a select number of organization for 3 months internship
 with our developers prior to graduation</p>


<h5><span className="rounded bg-dark p-1 text-white mx-1 lead">7</span> Our Curriculum Context</h5>
<p style={{color : "rgb(103, 118, 139)"}}>We provide hands-on software engineering & techniques. 
We curated the best curriculum to fit the best practice in the industry.</p>

		</Col>


		<Col md={6} xs={12} sm={12} style={{margin : 0, padding : 0}}>
		<Card className="border-0">
  <CardBody>
		<img src={image6} alt="ii" width="100%" style={{height : "500px", objectFit: "cover"}} className="rounded"/>
		</CardBody>
		</Card>
		</Col>
		</Row>
		</Container>
	)
}

export default Index
